import "./ContactForm.scss";

import React from "react";
import { withPrefix } from "gatsby";
import axios from "axios";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Subrayado1 from "../../svg/subrayados/subrayado01.svg";
import Separador from "../../svg/lineaSeparador.svg";

const FORM_ENDPOINT = "";

export default function UneteFormFiles() {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		name: "",
		phone: "",
		from_email: "",
		city: "",
		hours: "",
		about_you: "",
		cv_file: "",
		section: "Contacto",
		data_protection: "No",
		com_comercial: "No",
	});

	const [formSent, setFormSent] = React.useState(false);
	const [formError, setFormError] = React.useState(false);
	const [fileError, setFileError] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setFormError(false);
		setLoading(true);

		axios({
			method: "post",
			url: "https://peim.es/php_test/begin/envioUneteMailer.php", // for testing purposes. ACTIVADO PORQUE EN EL HOSTING DE BEGIN NO FUNCIONA
			// url: withPrefix("/php/envioUneteMailer.php"),
			data: {
				...toSend,
			},
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
			.then((res) => {
				setLoading(false);

				setFormSent(true);
				// console.log(res);
			})
			.catch((err) => {
				setLoading(false);
				setFormError(true);
				console.log(err);
			});
	};

	const handleChange = (e) => {
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked
						? "Si. Fecha: " +
						  new Date() +
						  ". Texto: " +
						  e.target?.labels[0]?.innerText
						: "No",
				};
			});
		}
		if (e.target.type === "file") {
			setFileError(false);
			const file = e.target.files[0];
			const maxSize = 5000; // max size in kb
			const allowedFileTypes = [
				"image/jpeg",
				"image/png",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				"application/pdf",
			];
			if (
				!allowedFileTypes?.includes(file?.type) ||
				file?.size / 1024 > maxSize
			) {
				setFileError(true);
			}
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.files[0],
				};
			});
		}
		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	return (
		<div>
			<SwitchTransition mode="out-in">
				<CSSTransition key={formSent} timeout={400} classNames="fade">
					<>
						{!formSent ? (
							<div>
								<form
									action={FORM_ENDPOINT}
									onSubmit={handleSubmit}
									onChange={handleChange}
									method="POST"
									target="_blank"
									className="contactForm"
									id="contactoForm"
								>
									<div>
										<input
											type="text"
											placeholder={t("Nombre y apellido")}
											id="name"
											name="name"
											title=" "
											required
											disabled={formSent}
										/>
										<Separador />
									</div>

									<div>
										<input
											type="text"
											placeholder={t("Teléfono")}
											id="phone"
											name="phone"
											title=" "
											required
											disabled={formSent}
										/>
										<Separador />
									</div>

									<div>
										<input
											type="email"
											placeholder={t("Email")}
											id="from_email"
											name="from_email"
											title=" "
											required
											disabled={formSent}
										/>
										<Separador />
									</div>

									<div className="selectContainer">
										<select
											id="city"
											name="city"
											placeholder={t("¿En qué ciudad te gustaría trabajar?")}
											className="p1 formel"
											defaultValue=""
											required
											disabled={formSent}
										>
											<option value="" disabled>
												{t("¿En qué ciudad te gustaría trabajar?")}
											</option>
											<option value="Valencia">{t("Valencia")}</option>
											<option value="Gandia">{t("Gandia")}</option>
										</select>
										<Separador />
									</div>

									<div className="selectContainer">
										<select
											id="hours"
											name="hours"
											placeholder={t("¿Qué jornada te interesaría?")}
											className="p1 formel"
											defaultValue=""
											required
											disabled={formSent}
										>
											<option value="" disabled>
												{t("¿Qué jornada te interesaría?")}
											</option>
											<option value="Media jornada (20h)">
												{t("Media jornada (20h)")}
											</option>
											<option value="Jornada parcial (30h)">
												{t("Jornada parcial (30h)")}
											</option>
											<option value="Jornada completa (40h)">
												{t("Jornada completa (40h)")}
											</option>
										</select>

										<Separador />
									</div>

									<div>
										<TextareaAutosize
											placeholder={t("Cuéntanos sobre ti")}
											id="about_you"
											name="about_you"
											title=" "
											required
											value={toSend.about_you}
											maxRows={8}
											minRows={1}
											disabled={formSent}
										/>
										<Separador />
									</div>

									<div className="fileInput">
										<label htmlFor="cv_file">
											<input
												type="file"
												accept=".pdf, .doc, .docx, .jpg, .png"
												name="cv_file"
												id="cv_file"
												required
												onChange={handleChange}
												disabled={formSent}
											/>
											<span>
												<Trans>
													Currículum (máx 5MB, tipo de archivo: pdf, doc, docx,
													jpg, png, jpeg)
												</Trans>
											</span>
											<br />
											<div className="fileButton">
												<span>
													{toSend.cv_file ? (
														toSend.cv_file.name
													) : (
														<Trans>Seleccionar archivo</Trans>
													)}
												</span>
											</div>
											{fileError && (
												<span className="fileErrorMessage">
													<Trans>
														X Error: archivo no válido. Revisa el formato o
														tamaño.
													</Trans>
												</span>
											)}
										</label>
									</div>

									<div className="dataProtection">
										<div className="dataCheck">
											<input
												type="checkbox"
												name="data_protection"
												id="data_protection"
												required
												disabled={formSent}
											/>
											<label
												htmlFor="data_protection"
												aria-label={t(
													"Consiento el uso de mis datos personales para que atiendan mi solicitud"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento el uso de mis datos personales para que
														atiendan mi solicitud, según lo establecido en su{" "}
														<a
															href="/legal/politica-privacidad"
															target="_blank"
															rel="noopener noreferrer"
														>
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</a>{" "}
														y declaro tener cumplidos los catorce (14) años de
														edad, asumiendo las posibles responsabilidades
														legales sobre la veracidad de esta declaración.
													</Trans>
												</p>
											</label>
										</div>

										<div className="dataCheck">
											<input
												type="checkbox"
												name="com_comercial"
												id="com_comercial"
												disabled={formSent}
											/>
											<label
												htmlFor="com_comercial"
												aria-label={t(
													"Consiento la recepción de comunicaciones del restaurante por e-mail y/o SMS con fines comerciales"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento la recepción de comunicaciones del
														restaurante por e-mail y/o SMS con fines comerciales
													</Trans>
												</p>
											</label>
										</div>
									</div>

									<div className="formSubmit">
										{loading && !formError ? (
											<p>Enviando...</p>
										) : (
											<>
												<button
													type="submit"
													aria-label={t("Enviar")}
													disabled={formSent || fileError}
												>
													<h3>
														<Subrayado1 className="subrayado" />
														<Trans>Enviar</Trans>
													</h3>
												</button>
												{formError && (
													<div>
														<p>
															<Trans>
																¡Ups! Ha habido un error en el envío del
																mensaje. ¡Lo sentimos!
															</Trans>
														</p>
														<p>
															<Trans>
																Vuelve a intentarlo en unos momentos. Si el
																problema persiste, también puedes contactarnos a
																través del chat o de nuestras Redes Sociales:
															</Trans>
														</p>
													</div>
												)}
											</>
										)}
									</div>
								</form>
							</div>
						) : (
							<div>
								<p>
									<Trans>
										Solicitud recibida. Pronto nos pondremos en contacto
										contigo.
									</Trans>
								</p>
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
